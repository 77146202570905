import { defineMessage } from '@lingui/macro'
import { FaqContentType } from './faq'
import Link from 'next/link'
import { LinguiJSMessageDescriptor } from '@lib/types/common'

export const CODING_SUMMER_CAMP_HIGHLIGHT = ['Code', 'Build', 'Hack']

export const CODING_SUMMER_CAMP_DETAILS = [
  {
    title: 'Age group',
    description: <>7-17 years</>,
  },
  {
    title: 'Program delivery',
    description: <>Online</>,
  },
  {
    title: 'Batch size',
    description: <>2-4 students </>,
  },
  {
    title: 'Session',
    description: (
      <>
        6 x 1-hour
        <br />
        sessions
      </>
    ),
  },
  {
    title: 'Duration',
    description: <>6 days</>,
  },
  {
    title: 'Certification by',
    description: <>STEM.org</>,
  },
]

export const couponCode = 'SUMMER50'

export type CourseType = {
  title: JSX.Element | string
  description: string
  age: string
  sessions: number
  image: string
  altText: string
  paymentLinkURL: string
  outcomes: string[]
  curriculumCard?: {
    title
    description: string
    projects: number
    quizzes: number
    keyLearningOutcomes: string[]
    exclusiveBenifits: string[]
    lessonPlan: {
      title: string
      description: string
      imagepath?: string
    }[]
  }
}

export const COURSES: CourseType[] = [
  {
    title: (
      <>
        Scratch Explorer: <br />
        Foundations of Coding
      </>
    ),
    age: '7-10',
    sessions: 6,
    description:
      'Designed to enhance problem-solving with animation and game creation.',
    image: 'scratch-beginners.png',
    altText: 'Scratch coding acedemy near me',
    paymentLinkURL: 'https://book.stripe.com/fZecOvgKF1UDbVmbMl',
    outcomes: [
      'Learn basic coding',
      'Make games and animations',
      'Get creative with digital tools',
    ],
    curriculumCard: {
      title: 'Scratch Explorer: Foundations of Coding',
      description:
        'Learn to code with Scratch! Start with the basics and work up to creating your own games like Pacman, all while having fun and getting creative.',
      projects: 10,
      quizzes: 6,
      keyLearningOutcomes: [
        'Sprite Manipulation',
        'Direction and Motion',
        'Visual Design in Scratch',
        'Game Development Basics',
        'Interactive Storytelling and Simations',
      ],
      exclusiveBenifits: [
        'Unleashing Creativity',
        'Getting Tech-Savvy',
        'Learn Scratch',
        'Create Cool Games',
        'Get Certified in Basic Scratch',
      ],
      lessonPlan: [
        {
          title: 'Introduction to Scratch​',
          description:
            'Discover the basics of Scratch, from navigating the interface to creating your first simple program.',
        },
        {
          title: 'Direction Sense',
          description:
            'Learn how to make characters move up, down, and all around the screen. Learn about the coordinate system.',
        },
        {
          title: 'Visuals',
          description:
            'Add fun sprites and costumes, and learn how to make a dress-up game by building "Dress Up Tera".',
        },
        {
          title: 'Solar System',
          description: 'Create a moving model of the planets orbiting the sun.',
        },
        {
          title: 'Pacman - 1',
          description:
            'Begin building your own Pacman game by setting up the maze and making Pacman move.',
        },
        {
          title: 'Pacman - 2',
          description:
            'Finish your Pacman game by adding ghosts and points to make it exciting and challenging.',
        },
      ],
    },
  },
  {
    title: (
      <>
        Scratch Champion: <br />
        Code Like a Pro
      </>
    ),
    age: '7-10',
    sessions: 6,
    description:
      'Blends science, art, microscopy, painting, and game dev for skill enhancement.',
    image: 'advanced-scratch.png',
    altText: 'kids and teens learning python programming',
    paymentLinkURL: 'https://book.stripe.com/28og0Hcupczh9Ne7wh',
    outcomes: [
      'Create games',
      'Try digital painting',
      'Explore the microscopic world',
    ],
    curriculumCard: {
      title: 'Scratch Champion: Code Like a Pro',
      description:
        'For students ready to go beyond the basics, Advanced Scratch provides an opportunity to explore intricate programming projects and advanced features in Scratch.',
      projects: 10,
      quizzes: 6,
      keyLearningOutcomes: [
        'Microscopy',
        'Digital Painting Techniques',
        'Game Development Fundamentals',
        'Interactive Game Design',
        'Integration of Art and Science in Projects',
      ],
      exclusiveBenifits: [
        'Become a Scratch Pro',
        'Build Awesome Games',
        'Boost Your Creativity',
        'Level Up Your Skills',
        'Earn a Scratch Champion Certificate',
      ],
      lessonPlan: [
        {
          title: 'Microscope',
          description:
            'Get a closer look at things by making a virtual microscope.',
        },
        {
          title: 'Transitions',
          description:
            'Learn how to switch scenes smoothly to tell better stories in your games.',
        },
        {
          title: 'Paint I',
          description:
            'Start making a paint program with basic tools like brushes and colors.',
        },
        {
          title: 'Paint II',
          description:
            'Add more cool features to your paint program like layers and custom effects.',
        },
        {
          title: 'Flappy In Space Part 1',
          description:
            'Begin making a space version of the popular Flappy Bird game, focusing on how things move.',
        },
        {
          title: 'Flappy In Space Part 2',
          description:
            'Finish your space game by adding challenges and keeping score.',
        },
      ],
    },
  },
  {
    title: (
      <>
        Python Foundations: <br />
        Learn to Code
      </>
    ),
    age: '11-17',
    sessions: 6,
    description:
      'Learn Python basics, operators, conditionals, & solve challenge.',
    image: 'python-beginners.png',
    altText: 'Python for teens and highschoolers',
    paymentLinkURL: 'https://book.stripe.com/5kAbKr0LHfLte3u6sf',
    outcomes: [
      'Write your first Python script',
      'Python datatypes and conditionals',
      'Practice with coding challenges',
    ],
    curriculumCard: {
      title: 'Python Foundations: Learn to Code',
      description:
        'Equip your child with Python essentials: data types, operators, conditionals, and more. Our course fosters problem-solving, laying a strong foundation for programming',
      projects: 10,
      quizzes: 6,
      keyLearningOutcomes: [
        'Python fundamentals mastery',
        'Problem-solving proficiency',
        'Enhanced logical thinking',
        'Strong coding foundation',
      ],
      exclusiveBenifits: [
        'Improved aptitude',
        'Skillful Python coding',
        'Effective problem-solving',
        'Logical reasoning',
      ],
      lessonPlan: [
        {
          title: 'Welcome to Programming',
          description:
            'Introduction to the world of programming and its significance in modern technology.',
        },
        {
          title: 'Getting Started with Programming',
          description:
            'Initial steps into coding, understanding the basic syntax, variables, and more.',
        },
        {
          title: 'Data Types in Python',
          description:
            'Exploring fundamental data types like strings, integers, and floats in Python.',
        },
        {
          title: 'Python Operators',
          description:
            'Dive into arithmetic, comparison, assignment, and logical operators for computation and manipulation.',
        },
        {
          title: 'Conditional Statements',
          description:
            'Learn how to control program flow using if, elif, and else statements based on conditions.',
        },
        {
          title: 'Python Challenge',
          description:
            'Applying acquired knowledge and skills through practical problem-solving exercises to reinforce learning.',
        },
      ],
    },
  },
  {
    title: (
      <>
        Python Champion: <br />
        Enhance Your Skills
      </>
    ),
    age: '11-17',
    sessions: 6,
    description:
      'Explore Python loops, nested conditions, patterns, and Turtle graphics.',
    image: 'advanced-python.png',
    altText: 'Python for teens and highschoolers',
    paymentLinkURL: 'https://book.stripe.com/8wM4hZamhar9f7yeYK',
    outcomes: [
      'Nested conditions for smarter choices',
      'Loops &  patterns',
      'Draw with Python’s Turtle graphics',
    ],
    curriculumCard: {
      title: 'Python Champion: Enhance Your Skills',
      description:
        'Discover Python’s potential with conditionals, loops, and Turtle graphics. Empower your child’s problem-solving and creativity',
      projects: 10,
      quizzes: 6,
      keyLearningOutcomes: [
        'Master loops',
        'Problem-solving patterns',
        'Draw with Python’s Turtle graphics',
        'Use nested conditions for smarter choices',
      ],
      exclusiveBenifits: [
        'Improved aptitude',
        'Skillful Python coding',
        'Effective problem-solving',
        'Logical reasoning',
      ],
      lessonPlan: [
        {
          title: 'Nested Conditional Statements',
          description:
            'Using multiple layers of conditions for complex decision-making in programs.',
        },
        {
          title: 'Loops',
          description:
            'Introduction to iterative structures for repeating tasks efficiently.',
        },
        {
          title: 'While Loop',
          description:
            'Learn a type of loop that continues until a condition changes.',
        },
        {
          title: 'Nested Loop',
          description:
            'Utilizing loops within loops for intricate repetitive tasks and pattern generation.',
        },
        {
          title: 'Patterns',
          description:
            'Creating visual and numerical patterns using loops and conditional statements.',
        },
        {
          title: 'Introduction to Turtle',
          description:
            'Introducing the Turtle module for graphical programming, fostering creativity and visual learning.',
        },
      ],
    },
  },
  {
    title: (
      <>
        Website Wizard: <br />
        Build Your First Website
      </>
    ),
    age: '11-17',
    sessions: 6,
    description:
      'Text formatting, multimedia, tables, forms, & create a mini-project in HTML.',
    image: 'web-dev-beginners.png',
    altText: 'Kids and teens building their first website',
    paymentLinkURL: 'https://book.stripe.com/9AQ4hZ2TP0Qz3oQ2c0',
    outcomes: [
      'HTML basics and media',
      'Explore tables and links',
      'Build a mini-project',
    ],
    curriculumCard: {
      title: 'Website Wizard: Build Your First Website',
      description:
        'Dive into HTML Basics, an exciting course that teaches you how to craft engaging web pages, from writing captivating text to embedding media and more, all topped off with a fun mini-project!',
      projects: 10,
      quizzes: 6,
      keyLearningOutcomes: [
        'HTML basics and structure',
        'Text formatting and linking',
        'Adding multimedia',
        'Creating tables and forms',
        'Using semantic HTML elements',
      ],
      exclusiveBenifits: [
        'Mastered HTML fundamentals',
        'Enhanced pages with text formatting',
        'Integrate various media types seamlessly',
        'Build interactive forms and tables',
        'Complete a comprehensive mini-project',
      ],
      lessonPlan: [
        {
          title: 'Introduction to HTML',
          description: 'Learn the foundational structure and basics of HTML.',
        },
        {
          title: 'Text Formatting, Lists, and Links',
          description:
            'Explore how to style text, create lists, and incorporate links into your web pages.',
        },
        {
          title: 'Multimedia and Embedding',
          description:
            'Discover methods to add images, videos, and other media, plus embedding external content.',
        },
        {
          title: 'Tables and Forms',
          description:
            'Gain skills in creating organized tables and interactive forms.',
        },
        {
          title: 'Semantic Elements in HTML',
          description:
            'Understand the importance and usage of semantic elements to enhance HTML readability and SEO.',
        },
        {
          title: 'Mini Project and Recap',
          description:
            'Apply everything learned to build a complete project, reviewing all key HTML concepts.',
        },
      ],
    },
  },
  {
    title: (
      <>
        Website Champion: <br />
        Develop Advanced Sites
      </>
    ),
    age: '11-17',
    sessions: 6,
    description:
      'Explore CSS from basics to advanced layouts, Flexbox, Grid, and animations.',
    image: 'advanced-web-dev.png',
    altText: 'website development for high schoolers',
    paymentLinkURL: 'https://book.stripe.com/aEU4hZeCxczh2kM5o2',
    outcomes: [
      'CSS basics and advanced features',
      'Grid and animation',
      'Create responsive designs',
    ],
    curriculumCard: {
      title: 'Website Champion: Develop Advanced Sites',
      description:
        'Unlock the power of CSS with this course! Learn layouts, selectors, animations, and responsive design to transform your web skills through fun, practical projects.',
      projects: 10,
      quizzes: 6,
      keyLearningOutcomes: [
        'Master CSS Box Model',
        'Utilize Advanced Selectors',
        'Design Responsive Layouts',
        'Implement CSS Animations',
        'Apply Flexbox and Grid',
      ],
      exclusiveBenifits: [
        'Build Professional Web Layouts',
        'Enhance Site Interactivity',
        'Optimize Mobile Views',
        'Create Animated Web Elements',
        'Complete Capstone Project',
      ],
      lessonPlan: [
        {
          title: 'Introduction to CSS',
          description:
            'Basic understanding of Cascading Style Sheets for web design.',
        },
        {
          title: 'Box Model, Layout, and Backgrounds',
          description:
            'Learn about element dimensions, positioning, and background styling.',
        },
        {
          title: 'Advanced Selectors, Pseudo-classes, and Pseudo-elements',
          description:
            'Target specific elements and create intricate styles using advanced CSS selectors.',
        },
        {
          title: 'Flexbox, Grid, and Responsive Design',
          description:
            'Master layout techniques for flexible and responsive web design across devices.',
        },
        {
          title: 'Transitions, Animations, and Transforms',
          description:
            'Add dynamic visual effects and interactivity to web pages using CSS.',
        },
        {
          title: 'Mini Project and Recap',
          description:
            'Apply everything learned to build a complete project, reviewing all key CSS concepts.',
        },
      ],
    },
  },
  {
    title: (
      <>
        App Inventor: <br />
        Build Your First App
      </>
    ),
    age: '7-13',
    sessions: 6,
    description: 'Learn to design and program AI apps on Thunkable.',
    image: 'app-dev-beginners.png',
    altText: 'how to build my app with Thunkable',
    paymentLinkURL: 'https://book.stripe.com/8wMcOv7a556P8JaaIn',
    outcomes: [
      'Create practical apps',
      'Design user interfaces',
      'Build Health Monitoring apps',
    ],
    curriculumCard: {
      title: 'App Inventor: Build Your First App',
      description:
        'Learn how to build mobile apps with Thunkable, a user-friendly platform that lets you create apps. Start from the basics and work your way up to designing fully functional apps.',
      projects: 10,
      quizzes: 6,
      keyLearningOutcomes: [
        'Introduction to Mobile App Development',
        'User Interface (UI) Design',
        'Health Monitoring App Development',
        'Creative Expression',
        'Problem Solving and Debugging',
      ],
      exclusiveBenifits: [
        'Master Thunkable Tools',
        'Create Personal Apps',
        'Enhance Health Awareness',
        'Unleash Creative Potential',
        'Launch Functional Apps',
      ],
      lessonPlan: [
        {
          title: 'Get Started Easily',
          description:
            'Learn how to use Thunkable’s simple tools to start making apps right away.',
        },
        {
          title: 'Family Tree',
          description:
            'Create a fun app to see and share about your family in a creative way',
        },
        {
          title: 'The Health App',
          description:
            'Build an app to keep an eye on your daily health and fitness activities.',
        },
        {
          title: 'Design a Drawing App',
          description:
            'Make a playful app where you can draw, scribble, and let your creativity flow.',
        },
        {
          title: 'My App I',
          description:
            'Begin building your app by adding features that allow it to make calls, send text messages, and send emails directly from the app.',
        },
        {
          title: 'My App II',
          description:
            'Finish your app by integrating more advanced tools such as a camera for taking pictures, a speech recognizer for voice commands, and maps for location services.',
        },
      ],
    },
  },
  {
    title: (
      <>
        App Champion: <br />
        Create Advanced App
      </>
    ),
    age: '7-13',
    sessions: 6,
    description:
      'Design and program complex AI apps on Thunkable along with UI design.',
    image: 'advanced-app-dev.png',
    altText: 'kids and teens building apps',
    paymentLinkURL: 'https://book.stripe.com/5kA01Jdyt56P1gI8Ag',
    outcomes: [
      'Develop complex apps',
      'Advanced interface design',
      'Project management and execution',
    ],
    curriculumCard: {
      title: 'App Champion: Create Advanced Apps',
      description:
        'Elevate your app development expertise with an advanced Thunkable course that teaches you to build intricate and functional apps such as interactive photo apps, messaging systems, and space application with Chat GPT intro.',
      projects: 10,
      quizzes: 6,
      keyLearningOutcomes: [
        'Advanced User Interface Design',
        'Data Management and Storage',
        'API Integration',
        'Advanced Features and Sensors',
        'Complex App Projects',
      ],
      exclusiveBenifits: [
        'Master Advanced App Tools',
        'Develop Complex Apps',
        'Enhance UI/UX Design Skills',
        'Build a Custom Messenger',
        'Chat GPT intro',
      ],
      lessonPlan: [
        {
          title: 'Snap It I',
          description:
            'Start an app project focused on taking and editing photos, learning to use advanced photo manipulation tools.',
        },
        {
          title: 'Snap It II',
          description:
            'Continue developing your photo app by adding more sophisticated features and also build a Google voice assistant.',
        },
        {
          title: 'Designing a Sign-in Screen',
          description:
            'Learn to design an effective and secure sign-in screen for apps, focusing on user experience and interface design.',
        },
        {
          title: 'Messenger App',
          description:
            'Develop a real-time messaging app that allows users to join a room, invite your friends, send texts, share emojis and have fun.',
        },
        {
          title: 'Space Explorer Part 1',
          description:
            'Start building an informative app about space. This lesson focuses on creating an app about various space-related topics and setting up the initial interface for user interaction.',
        },
        {
          title: 'Space Explorer Part 2',
          description:
            'Finish developing your space exploration app by adding a quiz to test users’ knowledge and integrating ChatGPT. This allows users to ask detailed questions about space።',
        },
      ],
    },
  },
  {
    title: (
      <>
        Roblox Rookie: <br />
        Build Fun Games
      </>
    ),
    age: '7-13',
    sessions: 6,
    description:
      'Introduction to Roblox studio, basic Lua scripting to 3D game creation.',
    image: 'roblox-beginners.png',
    altText: 'Learning roblox is fun',
    paymentLinkURL: 'https://book.stripe.com/dR6aGn8e9ar9cZq03L',
    outcomes: [
      'Basic Lua scripting',
      'Create User Interfaces',
      '3D Game Development',
    ],
    curriculumCard: {
      title: 'Roblox Rookie: Build Fun Games',
      description:
        'Dive into the world of Roblox game development and master its powerful tools and scripting language, ideal for beginners looking to create their own games.',
      projects: 10,
      quizzes: 6,
      keyLearningOutcomes: [
        'Understand Basic Lua Scripting',
        'Work with 3D Game Development',
        'Game Design Principles',
        'Create User Interfaces',
        'Develop Mini-Games',
      ],
      exclusiveBenifits: [
        'Master Roblox Basics',
        'Develop Coding Skills',
        'Design Custom UI',
        'Build 3D Models',
        'Create Own Mini-game',
      ],
      lessonPlan: [
        {
          title: 'Discovering Roblox',
          description:
            'Get introduced to Roblox Studio, learn how to navigate its interface, and explore the possibilities of game creation on this platform.',
        },
        {
          title: 'Basic Coding in Roblox',
          description:
            'Start with the basics of Lua scripting, Roblox’s programming language, to create simple game mechanics and scripts',
        },
        {
          title: 'Adding Advanced Obstacles Through Coding',
          description:
            'Dive deeper into coding by adding complex obstacles to your games, enhancing gameplay and learning to troubleshoot and optimize code.',
        },
        {
          title: 'Creating a User Interface in Roblox',
          description:
            'Learn how to design and implement user interfaces, such as menus, buttons, and scoreboards, to make your games more interactive and user-friendly.',
        },
        {
          title: 'Creating Models Using Roblox Properties',
          description:
            'Use Roblox’s built-in properties to create and manipulate 3D models, learning about the essentials of virtual building and asset customization.',
        },
        {
          title: 'Creating a Mini Game in Roblox',
          description:
            'Put all your skills to the test by developing a complete mini-game, focusing on gameplay, player interaction, and creativity.',
        },
      ],
    },
  },
  {
    title: (
      <>
        Roblox Champion: <br />
        Design Epic Games
      </>
    ),
    age: '7-13',
    sessions: 6,
    description:
      'Learn advanced game tricks, fancy controls with challenging levels.',
    image: 'advanced-roblox.png',
    altText: 'creating games with roblox and lua',
    paymentLinkURL: 'https://book.stripe.com/4gw9Cj8e9czhgbC17Q',
    outcomes: [
      'Develop Battle Mechanics',
      'Immersive Game Environments',
      'Design Complex Game Elements',
    ],
    curriculumCard: {
      title: 'Roblox Champion: Design Epic Games',
      description:
        'Transform your Roblox games into captivating experiences with advanced techniques in scripting, player control mastery, and immersive world creation.',
      projects: 10,
      quizzes: 6,
      keyLearningOutcomes: [
        'Design Complex Game Elements',
        'Develop Battle Mechanics',
        'Implement Teleportation and Other Mechanics',
        'Create Immersive Game Environments',
        'Advanced Camera and Player Controls',
      ],
      exclusiveBenifits: [
        'Master Advanced Scripting',
        'Build Complex Systems',
        'Develop Engaging Games',
        'Enhance Storytelling',
        'Complete a Multi-Level Project',
      ],
      lessonPlan: [
        {
          title: 'Setting up Camera and Player Control',
          description:
            'Learn how to customize camera views and player movement controls for a more dynamic gaming experience.',
        },
        {
          title: 'Implement Collectibles and Hazards',
          description:
            'Build a system for collecting items like coins and design death traps to challenge players, adding depth to your game mechanics.',
        },
        {
          title: 'Zombie Attack',
          description:
            'Develop a zombie attack scenario where players must defend against waves of enemies, introducing basic artificial intelligence (AI) behaviors.',
        },
        {
          title: 'Defeating the Fire Master by Spinning Fireballs',
          description:
            'Script a challenging boss fight against the Fire Master, using spinning fireballs as the main obstacle, to test players’ skills and strategy',
        },
        {
          title: 'Teleporting to Spider World (1)',
          description:
            'Start creating an expansive new game level that involves teleporting players to a uniquely themed ’Spider World’, focusing on environmental storytelling and seamless transitions.',
        },
        {
          title: 'Teleporting to Spider World (2)',
          description:
            'Continue to develop and refine the Spider World, adding more complex gameplay elements, challenges, and completing the immersive experience with final touches to storytelling and design.',
        },
      ],
    },
  },
  {
    title: (
      <>
        Data Science Intro: <br />
        Learn Python & Beyond
      </>
    ),
    age: '15-17',
    sessions: 6,
    description:
      'Learn the fundamentals of data science to  solve real-world problems.',
    image: 'data-science-beginners.png',
    altText: 'kids learning data science',
    paymentLinkURL: 'https://book.stripe.com/3cs15NdyteHp5wY4k3',
    outcomes: [
      'Python basics and functions',
      'Learn to use loops',
      'Explore data science',
    ],
    curriculumCard: {
      title: 'Data Science Intro: Learn Python & Beyond',
      description:
        'Embark on an enriching journey through Python basics: operators, data types, conditionals, loops, and functions. Unlock the gateway to data science adventures with confidence and curiosity.',
      projects: 10,
      quizzes: 6,
      keyLearningOutcomes: [
        'Master Python basics and functions',
        'Learn to use loops',
        'Data Operations',
        'Control Flow Mastery',
        'Explore data science',
      ],
      exclusiveBenifits: [
        'Python Proficiency',
        'Decision-Making Skills',
        'Iteration Efficiency',
        'Modular Programming',
        'Data Science Preparation',
      ],
      lessonPlan: [
        {
          title: 'Introduction to Python',
          description:
            'Begin your Python journey, mastering the language’s fundamentals, syntax, and structure, laying the groundwork for future exvploration and coding endeavors.',
        },
        {
          title: 'Operators and Data Types',
          description:
            'Dive into Python’s diverse set of operators and data types, understanding how they manipulate and represent information within your programs.',
        },
        {
          title: 'Conditional Statements and DateTime Module',
          description:
            'Learn to make decisions in your code using conditional statements, and explore the DateTime module for managing and manipulating dates and times effectively.',
        },
        {
          title: 'Loops',
          description:
            'Harness the power of iteration with loops, mastering the ability to execute a block of code repeatedly, essential for automating tasks and processing large datasets efficiently.',
        },
        {
          title: 'Functions',
          description:
            'Unlock the potential of modular programming with functions, organizing code into reusable blocks, enhancing readability, and streamlining development workflows.',
        },
        {
          title: 'Welcome to Data Science',
          description:
            'Step into the dynamic field of data science, equipped with Python skills to analyze, visualize, and draw insights from complex datasets, paving the way for impactful discoveries and innovations.',
        },
      ],
    },
  },
  {
    title: (
      <>
        Data Science Champion: <br />
        Advanced Analytics
      </>
    ),
    age: '15-17',
    sessions: 6,
    description: 'Introduction to data structures, OOPs and  libraries.',
    image: 'advanced-data-science.png',
    altText: 'data science for high school students of ages 14 to 17',
    paymentLinkURL: 'https://book.stripe.com/14kbKr7a556PbVmaIs',
    outcomes: [
      'Data structures, classes, & objects',
      'Apply Python in practical scenarios',
      'Introduction to Pandas and Matplotlib',
    ],
    curriculumCard: {
      title: 'Data Science Champion: Advanced Analytics',
      description:
        'Delve into Python’s advanced features with a focus on data structures and object-oriented programming. Learn to manipulate data efficiently with Pandas and visualize it with Matplotlib, unlocking new possibilities in data analysis and visualization.',
      projects: 10,
      quizzes: 6,
      keyLearningOutcomes: [
        'Advanced-Data Structure Implementation',
        'Proficiency in Object-Oriented Programming',
        'Data Manipulation with Pandas',
        'Data Visualization using Matplotlib',
        'Enhanced Skills for Data Analysis',
      ],
      exclusiveBenifits: [
        'Data Structures Expertise',
        'OOP Mastery',
        'Data Manipulation with Pandas',
        'Data Visualization with Matplotlib',
        'Advanced Analysis Readiness',
      ],
      lessonPlan: [
        {
          title: 'Data Structures in Python - 1',
          description:
            'Explore foundational data structures like lists, tuples, and dictionaries, mastering their implementation and manipulation techniques.',
        },
        {
          title: 'Data Structures in Python - 2',
          description:
            'Dive deeper into advanced data structures such as sets understanding their applications and optimizing their usage.',
        },
        {
          title: 'Object-Oriented Programming - 1',
          description:
            'Learn the principles of object-oriented programming (OOP), including classes, objects, inheritance, and polymorphism, laying the foundation for building complex, modular applications.',
        },
        {
          title: 'Object-Oriented Programming - 2',
          description:
            'Dive further into OOP concepts, exploring encapsulation, and abstraction, and applying them.',
        },
        {
          title: 'Introduction to Pandas',
          description:
            'Discover the power of Pandas for data manipulation and analysis, mastering techniques for loading, cleaning, and transforming data efficiently.',
        },
        {
          title: 'Introduction to Matplotlib',
          description:
            'Unlock the art of data visualization with Matplotlib, learning to create various types of plots and charts to communicate insights effectively.',
        },
      ],
    },
  },
]

export const CODINGAL_OFFERING = [
  {
    image: 'icon-1.png',
    imageWidth: 54,
    imageHeight: 32,
    content: 'Learn together with 2-4 coding buddies',
  },
  {
    image: 'icon-2.png',
    imageWidth: 32,
    imageHeight: 32,
    content: 'Handpicked coding instructor as per your need',
  },
  {
    image: 'icon-3.png',
    imageWidth: 32,
    imageHeight: 32,
    content: 'Fun & engaging classes of 1 hours each',
  },
  {
    image: 'icon-5.png',
    imageWidth: 32,
    imageHeight: 32,
    content: 'STEM.org accredited certificate for the course',
  },
  {
    image: 'icon-4.png',
    imageWidth: 32,
    imageHeight: 32,
    content: 'Unlimited doubt sessions and dedicated support available 24x7',
  },
  {
    image: 'icon-7.png',
    imageWidth: 32,
    imageHeight: 32,
    content: 'Get assured 100% moneyback guarantee',
  },

  {
    image: 'icon-9.png',
    imageWidth: 32,
    imageHeight: 32,
    content: 'Projects & Quizzes after every class',
  },
  {
    image: 'icon-10.png',
    imageWidth: 32,
    imageHeight: 32,
    content: 'Gamified learning dashboard with projects & quizzes',
  },
  {
    image: 'icon-8.png',
    imageWidth: 32,
    imageHeight: 32,
    content: 'Lifetime access to all class recordings',
  },
]
export type VideoTestimonialType = {
  name?: LinguiJSMessageDescriptor
  description?: LinguiJSMessageDescriptor
  type?: LinguiJSMessageDescriptor
  video: string
}

export const VIDEO_TESTIMONIAL: VideoTestimonialType[] = [
  {
    name: defineMessage({ message: 'Students Testimonial' }),
    description: defineMessage({ message: '' }),
    type: defineMessage({ message: 'Sneak Peek' }),
    video:
      'https://iframe.mediadelivery.net/embed/239253/4ba90c22-6c68-4bef-b169-d2fcfdebcc22?autoplay=true&loop=true&muted=false&preload=false&responsive=true',
  },
  {
    name: defineMessage({ message: 'Olti Merovci' }),
    description: defineMessage({ message: 'Grade 6' }),
    type: defineMessage({ message: 'Codingal Student' }),
    video:
      'https://iframe.mediadelivery.net/embed/239253/8811e6da-79f8-4a9e-932f-01ded0731dda?autoplay=true&loop=true&muted=false&preload=false&responsive=true',
  },
  {
    name: defineMessage({ message: 'Umaima Khan' }),
    description: defineMessage({ message: 'Grade 7' }),
    type: defineMessage({ message: 'Codingal Student' }),
    video:
      'https://iframe.mediadelivery.net/embed/239253/10202eae-2b1a-4649-8665-a147b1137fbe?autoplay=true&loop=true&muted=false&preload=false&responsive=true',
  },
  {
    name: defineMessage({ message: 'Mohammad Hamza' }),
    description: defineMessage({ message: 'Grade 7' }),
    type: defineMessage({ message: 'Codingal Student' }),
    video:
      'https://iframe.mediadelivery.net/embed/239253/47354ba8-47a2-477a-9947-676c620fcc0a?autoplay=true&loop=true&muted=false&preload=false&responsive=true',
  },
  {
    name: defineMessage({ message: 'Aaliyah Chambers' }),
    description: defineMessage({ message: 'Grade 7' }),
    type: defineMessage({ message: 'Codingal Student' }),
    video:
      'https://iframe.mediadelivery.net/embed/239253/50cd972b-db9a-44b1-a72a-0660d85cbd8b?autoplay=true&loop=true&muted=false&preload=false&responsive=true',
  },
  {
    name: defineMessage({ message: 'Lyla Ineson' }),
    description: defineMessage({ message: 'Grade 8' }),
    type: defineMessage({ message: 'Codingal Student' }),
    video:
      'https://iframe.mediadelivery.net/embed/239253/a6fb1430-7ebf-4f91-a072-2db8b89280bb?autoplay=true&loop=true&muted=false&preload=false&responsive=true',
  },
  {
    name: defineMessage({ message: 'Ray Ebihara' }),
    description: defineMessage({ message: 'Grade 5' }),
    type: defineMessage({ message: 'Codingal Student' }),
    video:
      'https://iframe.mediadelivery.net/embed/239253/e3744d4a-03a7-4486-b41b-cfe16be8ef93?autoplay=true&loop=true&muted=false&preload=false&responsive=true',
  },
  {
    name: defineMessage({ message: 'Trinity Golden Acquah' }),
    description: defineMessage({ message: 'Grade 5' }),
    type: defineMessage({ message: 'Codingal Student' }),
    video:
      'https://iframe.mediadelivery.net/embed/239253/5266e8ef-be64-430f-892a-5d8be93dd850?autoplay=true&loop=true&muted=false&preload=false&responsive=true',
  },
  {
    name: defineMessage({ message: 'Stefanie Ineson' }),
    description: defineMessage({ message: 'Film Industry' }),
    type: defineMessage({ message: 'Codingal Parent' }),
    video:
      'https://iframe.mediadelivery.net/embed/239253/2612d5ec-f1bc-4ab9-99ab-7324da617039?autoplay=true&loop=true&muted=false&preload=false&responsive=true',
  },
  {
    name: defineMessage({ message: 'Adwitiya Shaw' }),
    description: defineMessage({ message: 'Grade 6' }),
    type: defineMessage({ message: 'Codingal Student' }),
    video:
      'https://iframe.mediadelivery.net/embed/239253/c0ef650a-ecc5-4573-bda3-c85a95df4b91?autoplay=true&loop=true&muted=false&preload=false&responsive=true',
  },
  {
    name: defineMessage({ message: 'Pranshul Mankad' }),
    description: defineMessage({ message: 'Grade 8' }),
    type: defineMessage({ message: 'Codingal Student' }),
    video:
      'https://iframe.mediadelivery.net/embed/239253/9f9dfe08-955e-4eb1-8754-1026a215b1fa?autoplay=true&loop=true&muted=false&preload=false&responsive=true',
  },
]

export const TEACHER_INTRO = [
  {
    video:
      'https://iframe.mediadelivery.net/embed/239253/3ccc251c-4c3a-49d8-8b31-c1a037261cad?autoplay=true&loop=true&muted=false&preload=false&responsive=true',
  },
  {
    video:
      'https://iframe.mediadelivery.net/embed/239253/2698a80b-8311-47fd-b15c-22628789f133?autoplay=true&loop=true&muted=false&preload=false&responsive=true',
  },
]

export const FAQ_CONTENTS: FaqContentType[] = [
  {
    questionName: defineMessage({
      message: `What age groups can attend the Summer Coding Camp?
      `,
    }),
    acceptedAnswerText: (
      <>
        Our summer coding camp welcomes kids & teens aged 5-17. We offer
        specialized programs tailored to different age groups, ensuring an
        enriching and age-appropriate learning experience for all participants.
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `Our summer coding camp welcomes kids & teens aged 5-17. We offer specialized programs tailored to different age groups, ensuring an enriching and age-appropriate learning experience for all participants.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `Is prior coding experience required to join the summer coding camp for kids?`,
    }),
    acceptedAnswerText: (
      <>
        No, beginners are welcome! Our summer coding camp for kids and teens is
        designed to accommodate all skill levels, from absolute beginners to
        more advanced learners. Each student receives personalized guidance
        suited to their experience level.
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `No, beginners are welcome! Our summer coding camp for kids and teens is designed to accommodate all skill levels, from absolute beginners to more advanced learners. Each student receives personalized guidance suited to their experience level.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `What programming languages will participants learn at the coding camp?`,
    }),
    acceptedAnswerText: (
      <>
        Participants will explore a diverse range of programming languages and
        platforms, including Scratch, Python, Thunkable, Roblox Studio, Lua,
        HTML, CSS, JavaScript, and Data Science, providing them a broad
        foundation in computer programming depending on the camp selected.
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `Participants will explore a diverse range of programming languages and platforms, including Scratch, Python, Thunkable, Roblox Studio, Lua, HTML, CSS, JavaScript, and Data Science, providing them a broad foundation in computer programming depending on the camp selected.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `How are the virtual sessions of the coding camp conducted?`,
    }),
    acceptedAnswerText: (
      <>
        Our coding camp conducts live, interactive sessions through video
        conferencing software built into Codingal dashboard. Each session is led
        by an experienced instructor who provides real-time guidance and
        support, using best-in-class platform that enhances collaborative and
        interactive learning.
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `Our coding camp conducts live, interactive sessions through video conferencing software built into Codingal dashboard. Each session is led by an experienced instructor who provides real-time guidance and support, using best-in-class platform that enhances collaborative and interactive learning.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `What is the instructor-to-student ratio at the coding summer camp?`,
    }),
    acceptedAnswerText: (
      <>
        To ensure personalized attention and a supportive learning environment,
        we maintain a low instructor-to-student ratio with no more than 4
        students per class. This allows instructors to address individual needs
        and foster better learning outcomes.
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `To ensure personalized attention and a supportive learning environment, we maintain a low instructor-to-student ratio with no more than 4 students per class. This allows instructors to address individual needs and foster better learning outcomes.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `What equipment or software do participants need for the coding camp?`,
    }),
    acceptedAnswerText: (
      <>
        Participants will need a computer or laptop with a reliable internet
        connection. All software used in the camp for learning coding will be
        made available completely free of cost by Codingal.
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `Participants will need a computer or laptop with a reliable internet connection. All software used in the camp for learning coding will be made available completely free of cost by Codingal.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `Are there hands-on projects at the coding summer camp?`,
    }),
    acceptedAnswerText: (
      <>
        Absolutely! Our summer coding camp for kids and teens features engaging
        hands-on projects and group activities designed to reinforce coding
        skills, boost creativity, and encourage collaboration among
        participants.
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `Absolutely! Our summer coding camp for kids and teens features engaging hands-on projects and group activities designed to reinforce coding skills, boost creativity, and encourage collaboration among participants.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `How is participant progress assessed in the coding camp?`,
    }),
    acceptedAnswerText: (
      <>
        We track participants’ progress through activities, project evaluations,
        and continuous instructor feedback. Our camp emphasizes a comprehensive
        approach to learning, focusing on practical skills and understanding
        over rote memorization.
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `We track participants’ progress through activities, project evaluations, and continuous instructor feedback. Our camp emphasizes a comprehensive approach to learning, focusing on practical skills and understanding over rote memorization.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `Is technical support available during the coding summer camp?`,
    }),
    acceptedAnswerText: (
      <>
        Yes, technical support is readily available throughout the camp to
        assist students. Our dedicated team ensures that any technical
        challenges are promptly addressed, ensuring a seamless learning
        experience.
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `Yes, technical support is readily available throughout the camp to assist students. Our dedicated team ensures that any technical challenges are promptly addressed, ensuring a seamless learning experience.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `What additional support is provided beyond the online coding camp sessions?`,
    }),
    acceptedAnswerText: (
      <>
        Beyond regular sessions, students receive lifetime access to our
        learning resources, early invitations to global coding competitions and
        hackathons, unlimited doubt sessions, and 24/7 customer support. Our
        extensive support system is designed to foster ongoing learning and
        development in coding.
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `Beyond regular sessions, students receive lifetime access to our learning resources, early invitations to global coding competitions and hackathons, unlimited doubt sessions, and 24/7 customer support. Our extensive support system is designed to foster ongoing learning and development in coding.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `What is Codingal’s refund policy?`,
    }),
    acceptedAnswerText: (
      <>
        At Codingal, your satisfaction is our priority. That’s why we offer a
        100% money-back guarantee. If you feel that our services have not met
        your expectations, you are entitled to cancel your purchase and receive
        a full refund. Simply contact us at{' '}
        <a
          href="mailto:billing@codingal.com"
          target="blank"
          rel="noreferrer"
          className="text-blue-200 underline"
        >
          billing@codingal.com
        </a>{' '}
        to discontinue your services and request a refund for any remaining
        classes, with no questions asked.
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `At Codingal, your satisfaction is our priority. That’s why we offer a 100% money-back guarantee. If you feel that our services have not met your expectations, you are entitled to cancel your purchase and receive a full refund. Simply contact us at billing@codingal.com to discontinue your services and request a refund for any remaining classes, with no questions asked.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `Why should I trust Codingal?`,
    }),
    acceptedAnswerText: (
      <>
        Codingal is established by distinguished edtech entrepreneurs, alumni
        from IIT, and former professionals from Google and Amazon, and is
        supported by prominent investors like Y Combinator and Rebright
        Partners. You can learn more about our credentials and background{' '}
        <Link href="/about-us">
          <a className="text-blue-200 underline">here</a>
        </Link>
        . Additionally, we offer a money-back guarantee if you choose to
        withdraw after enrolling for any reason.
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `Codingal is established by distinguished edtech entrepreneurs, alumni from IIT, and former professionals from Google and Amazon, and is supported by prominent investors like Y Combinator and Rebright Partners. You can learn more about our credentials and background here. Additionally, we offer a money-back guarantee if you choose to withdraw after enrolling for any reason.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `How did Codingal get its name?`,
    }),
    acceptedAnswerText: (
      <>
        The name “Codingal” blends “coding” with “magical,” reflecting the fun &
        engaging experience of learning coding for kids. This combination
        highlights our commitment to making coding a magical and transformative
        journey.
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `The name “Codingal” blends “coding” with “magical,” reflecting the fun & engaging experience of learning coding for kids. This combination highlights our commitment to making coding a magical and transformative journey.`,
    }),
  },
]
