import { FastlyImage } from '@components/common/image'
import { Trans } from '@lingui/macro'

const COMMON_IMAGE_PATH = '/images/common/'

const Partners = () => {
  return (
    <div className="container mx-auto text-grey-800 flex flex-col justify-center lg:flex-row space-y-6 lg:space-y-0 lg:space-x-24 py-16 px-5">
      <div className="text-center">
        <p className="mb-2">
          <Trans>Built by alumni of</Trans>
        </p>
        <div className="flex gap-x-12 justify-center">
          <FastlyImage
            src={`${COMMON_IMAGE_PATH}/partners/google-medium.png`}
            height={41}
            width={120}
            alt="Google logo"
          />
          <FastlyImage
            src={`${COMMON_IMAGE_PATH}/partners/amazon.png`}
            height={39}
            width={128}
            alt="Amazon logo"
          />
        </div>
      </div>

      <div className="text-center">
        <p className="mb-2">
          <Trans>Backed by</Trans>
        </p>
        <div className="flex gap-x-12 justify-center">
          <FastlyImage
            src={`${COMMON_IMAGE_PATH}/partners/ycombinator.png`}
            height={41}
            width={195}
            alt="Y combinator logo"
          />
          <FastlyImage
            src={`${COMMON_IMAGE_PATH}/partners/rebright.png`}
            height={41}
            width={150}
            alt="Google logo"
          />
        </div>
      </div>

      <div className="text-center">
        <p className="mb-2">
          <Trans>Accredited by</Trans>
        </p>
        <FastlyImage
          src={`${COMMON_IMAGE_PATH}/partners/stem-bg-white.png`}
          alt="Google logo"
          height={52}
          width={114}
        />
      </div>
    </div>
  )
}
export default Partners
