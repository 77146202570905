import Button from '@components/atoms/button'
import { FastlyImage } from '@components/common/image'
import { LinguiJSMessageDescriptor } from '@lib/types/common'
import { defineMessage, t, Trans } from '@lingui/macro'
import Link from 'next/link'

const IMAGE_PATH = '/images/home/articles/v1/'

type ArticleDataType = {
  link: string
  image: {
    name: string
    alt: LinguiJSMessageDescriptor
    height: number
    width: number
  }
}

const ARTICLE_DATA: ArticleDataType[] = [
  {
    link:
      'https://yourstory.com/2021/03/hackerearth-coding-startup-codingal-y-combinator-winter-batch/amp',
    image: {
      name: 'yourstory',
      alt: defineMessage({ message: 'YourStory' }),
      height: 30,
      width: 150,
    },
  },

  {
    link:
      'https://www.edexlive.com/news/2021/feb/23/14-year-olds-virtual-classroom-app-wins-iit-roorkees-app-innovation-challenge-18479.html',
    image: {
      name: 'indian-express',
      alt: defineMessage({ message: 'Indian express' }),
      height: 50,
      width: 150,
    },
  },
  {
    link:
      'https://theprint.in/ani-press-releases/14-year-old-builds-personalised-news-app-becomes-co-founder-of-cloud-computing-firm/730993/',
    image: {
      name: 'the-print',
      alt: defineMessage({ message: 'The Print' }),
      height: 30,
      width: 150,
    },
  },
  {
    link:
      'https://www.livemint.com/companies/start-ups/ycbacked-codingal-raises-1-2-mn-in-seed-round-11630498657895.html',
    image: {
      name: 'mint',
      alt: defineMessage({ message: 'Mint logo' }),
      height: 35,
      width: 100,
    },
  },
  {
    link:
      'https://www.business-standard.com/content/press-releases-ani/14-year-old-builds-personalised-news-app-becomes-co-founder-of-cloud-computing-firm-121090901214_1.html',
    image: {
      name: 'business-standard',
      alt: defineMessage({ message: 'Business standard logo' }),
      height: 30,
      width: 250,
    },
  },
  {
    link:
      'https://www.vanguardngr.com/2024/03/nigerian-students-embrace-codingal-bridging-digital-divide-with-21st-century-skills/',
    image: {
      name: 'vanguard',
      alt: defineMessage({ message: 'Vanguard logo' }),
      height: 30,
      width: 135,
    },
  },
]

const Article = ({ link, image }: ArticleDataType) => (
  <a href={link} target="_blank" rel="noopener noreferrer">
    <FastlyImage
      src={`${IMAGE_PATH}${image.name}.png`}
      alt={t({ id: image.alt.id })}
      height={image.height}
      width={image.width}
    />
  </a>
)

const Articles = () => (
  <div className="w-full px-3 py-10 mx-auto lg:px-0 lg:py-16 lg:max-w-6xl">
    <h3 className="text-2xl leading-tight text-center text-grey lg:text-4xl font-600">
      <Trans>
        Codingal often <span className="underlined-text">makes the news</span>
      </Trans>
    </h3>
    <div className="flex flex-col items-center justify-center py-8 space-y-10 lg:space-y-0 lg:flex-row align-center lg:space-x-12 lg:py-16 lg:max-w-6xl lg:mx-auto">
      {ARTICLE_DATA.map((article, index) => (
        <Article key={index} link={article.link} image={article.image} />
      ))}
    </div>
    <div className="flex items-center justify-center">
      <Link href="/press">
        <a>
          <Button variant="outline">
            <Trans>See all news articles</Trans>
          </Button>
        </a>
      </Link>
    </div>
  </div>
)

export default Articles
